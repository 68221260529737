<template>
  <div v-if="isOpen" class="usp-alert" :class="`bg-${backgroundColor}`">
    <div class="usp-alert__header">
      <h4 class="usp-alert__title">{{ contentStore.content?.whyLogin }}</h4>
      <ButtonsIconButton
        v-if="isClosable"
        :icon-width="26"
        :icon-height="24"
        color="white"
        label="Sluiten"
        icon="close"
        @click="isOpen = false" />
    </div>
    <ul class="usp-alert__list">
      <li v-for="(usp, index) in usps" :key="`usp-${index}`">
        <Usp :text="usp.text" :icon="usp.icon" />
      </li>
    </ul>
    <slot>
      <ButtonsBaseButton
        type="button"
        icon="user"
        variant="solid"
        color="white"
        @click="handleSignin">
        {{ contentStore.content?.loginForPriceDeals }}
      </ButtonsBaseButton>
    </slot>
  </div>
</template>

<script lang="ts" setup>
import type { Usp } from '@/types/usp';
import type { Color } from '@/types/color';

defineProps<{
  isClosable: boolean;
  usps: Usp[];
  backgroundColor: Color;
}>();

const contentStore = useContentStore();

const isOpen = ref(true);

const { handleSignin } = useSignin();
</script>

<style lang="scss" scoped>
.usp-alert {
  color: $c-white;
  padding: px(16);
  border-radius: px(8);
  display: grid;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: px(16);
  }

  &__list {
    margin-top: 0;
    margin-bottom: px(24);
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: px(8);
  }
}
</style>
